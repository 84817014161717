$primaryTextColor: black;
$primaryCubeBackground: rgba(244, 244, 244, 0.7);
$seconderycubeColor: black;
$seconderyTextColor: #f4f4f4;
$generalEffectColor: #c8b900;
$boxShadowColor: rgba(63, 63, 63, 0.8);


/*
online design variables 
*/
$onlineMainColor: #12678c;
$onlineDarkColor: #5b7794;
$onlineLightColor: #209CC9;
$onlineSeconderyColor: aliceblue;
$specialBtn: #E62343;
$specialBtn2: #28a745;
$onlineSeconderyText: #fff;
$spcialOffers: #d4ce47;
$boxBorder: #C1C5C647;

$globalOpacity: 0.85;
//old
/* $primaryColor: #ffeb3b;
$primaryColorLight: #ffff72;
$primaryColorDark: #c8b900;
$secondryColor: black;
$seconderyColorLight: #f4f4f4;
$primaryTextColor: black; 
$seconderyTextColor: #fff; */

$transparentBackground: rgb(10 54 100 / 90%);