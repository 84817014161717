/* You can add global styles to this file, and also import other style files */

@import "sass/variables.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~font-awesome/css/font-awesome.min.css";
@font-face {
  font-family: GveretLevin;
  font-display: swap;
  src: url(/assets/fonts/GveretLevinAlefAlefAlef-Regular.woff);
}

/* hebrew */

@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/assistant/v2/2sDcZGJYnIjSi6H75xkzamW5Kb8VZBHR.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */

@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/assistant/v2/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body,
html {
  margin: 0px;
}

body {
  min-height: 100vh;
  text-align: right;
  direction: rtl;
  //background-image: url(assets/cube.png);
  font-display: swap;
  background-color: rgb(223, 223, 223);
}

*::selection {
  background: $onlineLightColor;
}

body.body-online {
  font-family: "Assistant", sans-serif;
  text-align: right;
}

.orders-page {
  background: url(/assets/online_design/order-bck.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
}

.articles-page {
  background: url(/assets/online_design/articles-bck.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.basket-page {
  background: url(/assets/online_design/basket-bck.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.details-page {
  background: url(/assets/online_design/details-page-bck.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.online-content-cube-wrapper,
.content-cube-wrapper {
  padding-top: 20px;
  //box-shadow: 0 10px 20px $boxShadowColor;
  /* border: 1px solid white; */
  border-radius: 5px;
  margin-bottom: 20px;
}

.p {
  margin-bottom: 15px;
}

.underline-bold-text {
  font-weight: bold;
}

.input-field {
  margin-bottom: 15px;
}

.submit-btn {
  background-color: $onlineMainColor;
  color: $seconderyTextColor;
  border: 0px;
  height: 40px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.submit-btn-online {
  background-color: $specialBtn2;
  color: $seconderyTextColor;
  border: 0px;
  padding: 0.5rem;
  line-height: 1rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.logo-title {
  width: 100%;
  height: 150px;
  //border:1px solid black;
  background-image: url(/assets/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.loding-frame {
  background-color: rgba(255, 255, 255, 0.7);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.work-in-progress-txt {
  text-align: center;
}

.work-in-progress-frame {
  margin: auto;
  border: 1px solid gray;
  display: inline-block;
  border-radius: 20px;
  z-index: 10;
  padding: 20px;
  background: #fff;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.5);
  color: $onlineMainColor;
  font-weight: bold;
  font-size: 1.5em;
  min-width: 85vw;
}

.work-in-progress-load {
  background-image: url(/assets/loader-working.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 140px;
}

.hide-workinprogress-txt {
  animation: hideWorkingProgress 700ms forwards;
}

.show-workinprogress-txt {
  animation: showWorkingProgress 700ms forwards;
}

@keyframes hideWorkingProgress {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showWorkingProgress {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loading-icon {
  background-image: url(/assets/Spinner.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

app-menu {
  width: 100vw;
}

.page-wrapper {
  animation: 1s inserPage forwards;
  margin-bottom: 50px;
}

.msg-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
}

.success-msg,
.faild-msg {
  font-size: 2em;
  background-color: $specialBtn2;
  color: #fff;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  max-width: 80vw;
  //animation: 3s hideMsg forwards;
  z-index: 12;
  padding-top: 20px;
  border-radius: 5px;
  box-shadow: 8px 8px 13px rgba(0, 0, 0, 0.5);
  margin: auto;
  position: relative;
  text-align: center;
}

.close-wrapper {
  cursor: pointer;
  position: sticky;
  float: left;
  top: 0;
  display: flex;
  border: 1px solid;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  align-content: center;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  z-index: 2;
  font-size: 1.4em;
  background: #fff;
  div {
    margin: auto;
    display: inline-block;
    line-height: 1;
    font-weight: bold;
  }
}

.article-figure {
  text-align: center;
  float: left;
  margin-right: 20px;
  img {
    filter: drop-shadow(3px 3px 3px gray);
    max-height: 70vh;
  }
  figcaption {
    font-size: 0.7em;
    font-weight: bold;
  }
}

.iframe-article-p {
  text-align: center;
  iframe {
    width: 30vw;
    height: 300px;
  }
}

.MsoNormal {
  margin-bottom: 0;
}

.close-x-msg {
  background-color: rgba(255, 255, 255, 1);
  color: $onlineMainColor;
  font-size: 0.8em;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
  border: 1px solid;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

@keyframes inserPage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hideMsg {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.error {
  color: red;
  font-size: 0.8em;
  font-weight: bold;
  animation: 0.5s showError forwards;
}

@keyframes showError {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.special-title {
  width: 100%;
  border-bottom: 2px solid $onlineLightColor;
  font-weight: bold;
}

.pay-btn,
.coupon-btn {
  background-color: $specialBtn2;
  display: inline-block;
  padding: 5px;
  min-width: 50px;
  text-align: center;
  color: $onlineSeconderyText;
  font-weight: bold;
  margin: 10px;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  cursor: pointer;
  letter-spacing: 1px;
}

.pay-btn:hover,
.coupon-btn {
  background-color: #5d965d;
}

.back {
  margin: 10px;
  color: $onlineMainColor;
  font-weight: bold;
  cursor: pointer;
}

.price-discount {
  background-color: $onlineLightColor;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 100%;
  font-weight: bold;
  border-radius: 5px;
  text-align: left;
}

.file-name,
.remove-file-item {
  display: inline-block;
}

.file-name {
  color: $specialBtn2;
  margin-right: 10px;
}

.remove-file-item {
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8em;
  border: 1px solid;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  color: $onlineMainColor;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

.file-img {
  width: 175px;
  height: 175px;
  margin-left: 7px;
  margin-bottom: 7px;
  margin-top: 14px;
  text-align: center;
  img {
    height: 100%;
    max-width: 100%;
  }
  border: 1px solid gray;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  transition: 1s;
}

.dwg-pic {
  background-image: url("/assets/online_design/dwgicon.svg");
  background-size: 120px;
  background-position: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.img-caption {
  font-size: 0.8em;
  font-weight: bold;
  color: gray;
  text-align: center;
}

.pdf-pic,
.other-file-format-pic {
  font-size: 85px;
  color: $onlineLightColor;
}

.detail-text-images {
  display: flex;
  flex-wrap: wrap;
}

.pic-list-wrapper {
  display: flex;
  max-width: 60vw;
  flex-wrap: wrap;
}

.link-to-product-list-pb {
  position: fixed;
  bottom: 1.5rem;
  left: 1rem;
  float: left;
  margin: auto;
  z-index: 1;
  margin-top: 20%;
}

.price-discount-row {
  display: block;
  div {
    display: inline-block;
  }
}

.alert-danger {
  animation: 1s displayAlert forwards;
  padding-right: 5px;
  padding-left: 5px;
}

input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid $onlineMainColor;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

input[type="radio"] {
  border-radius: 100%;
}

input[type="checkbox"]:checked::after {
  content: "V";
  display: block;
  margin-top: -0.3rem;
  font-weight: bold;
  outline: none;
  text-align: center;
}

input[type="checkbox"]:checked::after:focus {
  outline: none;
}

input[type="checkbox"]:focus {
  outline: none;
}

input[type="checkbox"]:checked {
  color: #fff;
  font-weight: bold;
  animation: 0.4s animateInput forwards;
}

input[type="radio"]:checked {
  animation: 0.4s animateInput forwards;
}

@keyframes animateInput {
  from {
    background: #fff;
  }
  to {
    background: $onlineMainColor;
  }
}

@keyframes displayAlert {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.help-tooltip {
  direction: rtl !important;
  text-align: right;
  margin-top: 0.5rem;
}

.link-to-product-list-p {
  height: 40px;
  text-align: center;
}

.link-to-product-list {
  background-color: $specialBtn;
}

input.special-font,
textarea.special-font {
  font-family: GveretLevin !important;
  letter-spacing: 0.1rem;
}

.link-to-product-list,
.price-list-btn {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  color: #fff;
  line-height: 30px;
  margin-bottom: 5px;
  box-shadow: 9px 3px 8px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  letter-spacing: 1px;
}

.facbook-title {
  background-color: $transparentBackground;
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  padding-right: 8px;
}

.price-list-btn {
  background-color: $spcialOffers;
}

.link-to-product-list:hover,
.price-list-btn:hover {
  background-color: $onlineLightColor;
}

.tooltip > .tooltip-inner {
  background-color: $onlineLightColor !important;
  color: #fff;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.tooltip.in {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: $onlineLightColor;
}

.body-online {
  background-color: #fff;
}

.num-of-orders {
  position: absolute;
  top: -9px;
  font-weight: bold;
  color: $onlineMainColor;
  margin: auto;
  left: 0;
  right: -19px;
  font-size: 0.7em;
  text-shadow: 1px 1px 1px;
  text-align: center;
}

.basket-link-icon {
  position: fixed;
  right: 5px;
  top: 8px;
  font-size: 1.6em;
  color: $onlineMainColor;
  z-index: 6;
  background-image: url("/assets/online_design/shop-feepik.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 25px;
  height: 25px;
  svg {
    z-index: 7;
  }
}

.confirm-btns {
  flex-direction: row-reverse;
}

.popover-header {
  background: $onlineMainColor !important;
  color: #fff;
}

.alert-good {
  color: green;
}

.alert-riski {
  color: gray;
}

.alert-dangerous {
  color: red;
  font-weight: bold;
}

.admin-comment-date {
  display: block;
  font-size: 0.7em;
  color: $onlineMainColor;
}

.link-to-product-list {
  animation: 1.7s animateLinqToProduct infinite alternate linear;
}

@keyframes animateLinqToProduct {
  from {
    border: 1px solid $specialBtn;
  }
  to {
    border: 1px solid white;
  }
}

.article-content-pic {
  max-width: 50vw;
  max-height: 80vh;
  margin: auto;
  display: block;
}

@media (max-width: 769px) {
  body {
    max-width: 100vw;
    background-color: rgba(255, 255, 255, 0.7);
  }
  re-captcha div div iframe {
    transform-origin: 100% 0;
    transform: scale(0.77);
  }
  .online-content-cube-wrapper {
    max-width: 100vw;
    margin-top: 230px;
    padding-top: 0;
    overflow-x: hidden;
  }
  .link-to-product-list-pb {
    bottom: 1.5rem;
    &.link-to-product-list-scroll {
      text-align: center;
      animation: scrollOrderBtnWrapper 1s forwards ease-out;
      z-index: 10;
      .link-to-product-list {
        animation: scrollOrderBtn 1s forwards ease-in-out,
          changeColorOrderBtn 1.5s 1s infinite alternate-reverse;
      }
    }
  }
  @keyframes scrollOrderBtnWrapper {
    from {
      left: 1rem;
    }
    to {
      left: 20px;
    }
  }

  @keyframes changeColorOrderBtn {
    from {
      background-color: #e62343;
    }
    to {
      background-color: rgb(197, 73, 96);
    }
  }

  @keyframes scrollOrderBtn {
    from {
      width: 170px;
    }
    to {
      width: calc(100vw - 40px);
    }
  }

  .body-not-scroll {
    animation: 0.5s bodyNotScroll forwards;
  }
  .body-scroll {
    animation: 0.5s bodyScroll forwards;
  }
  .article-figure {
    width: 100%;
  }
  .iframe-article-p {
    iframe {
      width: 90vw;
      height: 300px;
    }
  }
  .orders-page {
    background: #e1e9ec;
  }
  .articles-page {
    background-color: #e1e9ec;
    background-image: none;
  }
  .details-page {
    background: url(/assets/online_design/details-page-bck-mob.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
  }
  .article-content-pic {
    max-width: 90vw;
    max-height: 80vh;
    margin: auto;
  }
  #player-walla {
    width: 100vw;
    height: calc(100vw / 1.76);
  }
}

@keyframes bodyNotScroll {
  from {
    margin-top: 80px;
  }
  to {
    margin-top: 200px;
  }
}

@keyframes bodyScroll {
  from {
    margin-top: 180px;
  }
  to {
    margin-top: 80px;
  }
}

.disable-anim {
  animation: none !important;
}

@media (min-width: 770px) {
  .num-of-orders-active {
    animation: 0.7s numOfOrderActive forwards;
  }
  @keyframes numOfOrderActive {
    from {
      background-color: $onlineMainColor;
    }
    to {
      background-color: $specialBtn;
    }
  }
}

.user-leave-alert {
  color: red;
}

.chat-share-icon {
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
}

.watsapp-chat-icon {
  background-image: url(/assets/online_design/WhatsApp_Logo.gif);
}

.messanger-chat-icon {
  background-image: url(/assets/online_design/messenger.png);
}

.mail-chat-icon {
  background-image: url(/assets/online_design/envelop.png);
}
.player-play {
  z-index: 100;
  position: relative;
}

.carousel-control-next,
.carousel-control-prev {
  background-color: transparent;
  border: 0;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  svg {
    fill: red;
  }
}

.carousel-indicators button {
  border: 0;
  margin-left: 5px;
  background: white;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 50%);
  transform: translateY(-10px);
}
.carousel .visually-hidden {
  display: none;
}
.final-price-num{
  display: block;

}

